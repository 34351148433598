import { AnchorContainer, GetYourSelected, GuideCategoryDropdown } from "./shared";
import {
  Container,
  DesktopContainer,
  PageWidth,
  Section,
  TabletContainer,
  VerticalSeparator,
} from "@util/standard";
import GuidesCards, { Props as GuideCardProps } from "./guidesCards";
import { SanityGuideCategory, SanityRegion } from "@graphql-types";
import React, { Dispatch, SetStateAction, createRef, useEffect, useState } from "react";
import { useHasScrolled, useIsOnScreen, useToggle } from "@util/hooks";

import { AnchorLink } from "@global";
import { Drawer } from "@accessible/drawer";
import { DrawerTarget } from "@shared";
import { GuidesInCategories } from "@state/types";
import MetguideForm from "./metguideForm";
import { assets } from "@util/constants";
import { sendMetguideViewItems } from "@util/datalayer";
import { useStore } from "@state/store";

interface Props {
  data: GuidesInCategories[];
}

export default function AllGuidesInCategories({ data }: Props) {
  if (!Boolean(data.length)) return null;
  const [dropdownVisible, toggle] = useToggle();
  const [selected, setSelected] = useState<SanityGuideCategory>();

  const { pageColor, getSelectedMetguides } = useStore();
  const selectedGuides = getSelectedMetguides();
  const { hasScrolled } = useHasScrolled();

  useEffect(() => {
    sendMetguideViewItems(data);
  }, []);

  return (
    <Drawer>
      <AnchorContainer scrollOffset={hasScrolled}>
        <DesktopContainer columnGap="30px">
          {data.map((guide, index) => {
            if (guide?.category == null) return null;
            const { title, categoryId } = guide.category;

            return (
              <Container key={index}>
                <AnchorLink title={title} anchor={categoryId?.current} />
                {data.length !== index + 1 && <VerticalSeparator margin="0 0 0 30px" />}
              </Container>
            );
          })}
        </DesktopContainer>
        <TabletContainer width="30%" tabletWidth="100%">
          <GuideCategoryDropdown onClick={toggle} dropdownVisible={dropdownVisible}>
            <div className="selected">
              <span>{selected?.title ?? "All guides"}</span>
              <img src={assets.chervon} />
            </div>
            {dropdownVisible && (
              <div className="items">
                {data.map((guide, index) => {
                  if (guide?.category == null) return null;
                  const { title, categoryId } = guide.category;
                  const handleClick = () => setSelected(guide.category);

                  return (
                    <AnchorLink
                      key={index}
                      title={title}
                      onClick={handleClick}
                      anchor={categoryId?.current}
                      color="navy"
                    />
                  );
                })}
              </div>
            )}
          </GuideCategoryDropdown>
        </TabletContainer>
      </AnchorContainer>

      <Section aria-label="Guides" marginOverride="150px 0 75px 0">
        {selectedGuides.length > 0 && (
          <GetYourSelected type="guide(s)" length={selectedGuides.length} pageColor={pageColor} />
        )}
        <DrawerTarget
          removeHeaderTransform
          title="Get your selected Metguides"
          drawerId="guides-form"
        >
          <Container flexDirection="column">
            <MetguideForm isDrawer />
          </Container>
        </DrawerTarget>
        <PageWidth>
          <Container flexDirection="column" rowGap="100px" margin="100px 0 0 0">
            {data.map((guide, index) => {
              if (guide == null) return null;
              const { forms, category } = guide;
              return (
                <RenderGuideCards
                  key={index}
                  data={forms}
                  category={category}
                  setSelected={setSelected}
                />
              );
            })}
          </Container>
          <Container height="100px" />
        </PageWidth>
      </Section>
    </Drawer>
  );
}

interface RenderGuideCardProps extends GuideCardProps {
  setSelected: Dispatch<SetStateAction<SanityGuideCategory | undefined>>;
}

const RenderGuideCards = ({ data, category, setSelected }: RenderGuideCardProps) => {
  const ref = createRef<HTMLDivElement>();
  const onScreen = useIsOnScreen(ref);

  useEffect(() => {
    if (onScreen) setSelected(category);
  }, [onScreen]);

  return <GuidesCards data={data} category={category} viewRef={ref} />;
};
