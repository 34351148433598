import React from "react";
import { PageProps, graphql } from "gatsby";

import { Blocks, ErrorMsg, Header, SEO } from "@shared";
import AllGuidesInCategories from "@components/forms/allGuidesInCategories";
import { GuidesLandingPageContext } from "@state/types";
import { Query } from "@graphql-types";
import { usePageMeta } from "@util/logicHooks";
import Popup from "@components/forms/popup";

type PageContext = PageProps & GuidesLandingPageContext;
interface Props {
  data: Query;
  pageContext: PageContext;
}

export default function GuidesLandingTemplate({
  data: { sanityGuidesLanding, sanityHeader },
  pageContext: { guidesInCategories },
}: Props) {
  if (sanityGuidesLanding == null)
    return <ErrorMsg data={sanityGuidesLanding} msg="Error fetching data for page" />;
  const { slug, _type, pageColour, seo, blocks, popupText } = sanityGuidesLanding;
  usePageMeta(pageColour?.colour?.hex, _type);

  return (
    <div>
      <SEO seoData={seo} slug={slug?.current as string} />
      <Header data={sanityHeader} />
      <AllGuidesInCategories data={guidesInCategories} />
      {blocks && <Blocks data={blocks} />}
      <Popup data={popupText} type="guide" />
    </div>
  );
}

export const query = graphql`
  query guidesLandingQuery {
    sanityHeader {
      ...sanityHeader
    }
    sanityGuidesLanding {
      _type
      title
      slug {
        current
      }
      seo {
        ...sanitySeo
      }
      blockContent {
        ...sanityBlockContent
      }
      pageColour {
        ...sanityColorPicker
      }
      blocks {
        ...staticPageBlocks
      }
      variationReference {
        ...sanityVariation
      }
      popupText {
        ...sanityBlockContent
      }
    }
  }
`;
